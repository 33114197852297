import { LinkContainer } from 'react-router-bootstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faHeart} from "@fortawesome/free-solid-svg-icons";
import {Container, Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";

library.add(faHeart);

function SignOutLink() {
    const signOut = (e) => {
        e.preventDefault();
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        window.location.reload();
    };

    return (
        // eslint-disable-next-line
        <a href="/logout" onClick={signOut}>
            Sign out
        </a>
    );
}

function NavBar({ currentUser }) {
    return (
        <Navbar sticky="top" bg="primary" variant="dark" expand="lg" className="mb-3">
            <Container fluid>
                <LinkContainer to="/">
                    <Navbar.Brand href="#home">
                        <img src="/synerg_logo_192.png" />&nbsp;SynerG Web Portal
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <LinkContainer to="/">
                            <Nav.Link>Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/sessions">
                            <Nav.Link>Sessions</Nav.Link>
                        </LinkContainer>
                        {currentUser.user_type === "ADMIN" &&
                            <LinkContainer to="/programming">
                                <Nav.Link>Programming</Nav.Link>
                            </LinkContainer>
                        }
                        {currentUser.user_type === "ADMIN" &&
                            <LinkContainer to="/registry">
                                <Nav.Link>Device Registry</Nav.Link>
                            </LinkContainer>
                        }
                        {currentUser.user_type === "ADMIN" &&
                            <LinkContainer to="/users">
                                <Nav.Link>Users</Nav.Link>
                            </LinkContainer>
                        }
                    </Nav>
                </Navbar.Collapse>

                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        Version {process.env.REACT_APP_VERSION}
                    </Navbar.Text>
                    <Navbar.Text>&nbsp;|&nbsp;</Navbar.Text>
                    <Navbar.Text>
                        User: <Link to="/profile">{currentUser.first_name} {currentUser.last_name}</Link> | <SignOutLink/>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;