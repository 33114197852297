import React, {useEffect, useState} from "react";
import {searchUsers} from "../../services/user";
import {getSessionList} from "../../services/session";
import {Badge, ButtonGroup, Card, Col, InputGroup, Row, Table} from "react-bootstrap";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

// function millisToDurationString(millis) {
//     const seconds = millis / 1000;
//     const minutes = seconds / 60;
//     const hours = minutes / 60;
//
//     if (hours > 1) {
//         const hours_floor = Math.floor(hours);
//         const minutes_rem = minutes - (hours_floor * 60);
//         return hours_floor + " hours, " + minutes_rem + " minutes";
//     }
//     else {
//         const minutes_floor = Math.floor(minutes);
//         const seconds_rem = Math.round(seconds - (minutes_floor * 60));
//         return minutes_floor + " minutes, " + seconds_rem + " seconds";
//     }
// }

function SessionListTableItem(props) {
    const item = props.item;

    return (
        <tr className={item.ended == null ? "table-success" : ""}>
            <td><code><strong><Link className="link-primary" to={`/sessions/${item.session_id}`}>{item.session_id.substring(24).toUpperCase()}</Link></strong></code></td>
            <td>{new Date(Date.parse(item.started)).toLocaleString()}</td>
            <td>{item.ended == null ? "" : new Date(Date.parse(item.ended)).toLocaleString()}</td>
            <td><code>{item.device_id}</code></td>
            <td><code>{item.sensor_id}</code></td>
            <td>
                <Badge bg="primary">HW: {item.hardware_version}</Badge>
                <Badge bg="secondary" className="ms-1">FW: v{item.firmware_version}</Badge>
                <Badge bg="secondary" className="ms-1">App v:{item.software_version}</Badge>
            </td>
        </tr>
    );
}

function SessionListTable(props) {
    const items = props.items;
    let listTableItems = items.map((item) =>
        <SessionListTableItem key={item.session_id} item={item}/>
    );

    if (listTableItems.length === 0) {
        listTableItems = (
            <tr>
                <td colSpan="8" align="center">No sessions</td>
            </tr>
        )
    }

    return (
        <Table className="table-striped">
            <thead>
            <tr>
                <th>Session ID</th>
                <th>Started</th>
                <th>Ended</th>
                <th>Device ID</th>
                <th>Sensor ID</th>
                <th>Version Info</th>
            </tr>
            </thead>
            <tbody>
            {listTableItems}
            </tbody>
        </Table>
    );
}

export default function Sessions({ currentUser, showSearch, forUser }) {
    const [sessions, setSessions] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchOptions, setSearchOptions] = useState([]);

    console.log("User selected");
    console.log(forUser);
    console.log(selectedUser);

    showSearch = showSearch === undefined ? true : showSearch;

    function handleSearch(query) {
        setSearchLoading(true);
        searchUsers(query).then(function(result) {
            setSearchLoading(false);
            if (result.success) {
                console.log("Got result");
                console.log(result.users);
                let opts = [];
                for (let i = 0; i < result.users.length; i++) {
                    opts.push({
                        id: result.users[i][0],
                        label: result.users[i][1]
                    });
                }
                setSearchOptions(opts);
            }
        });
    }

    function handleClearSearch(event) {
        event.preventDefault();
        setSelectedUser({});
    }

    useEffect(() => {
        const user_id = selectedUser.user_id ? selectedUser.user_id : forUser.user_id;
        console.log("Getting session list for: " + user_id);

        getSessionList(user_id).then(function(result) {
            if (result.success) {
                console.log("Got session list");
                console.log(result.sessions);
                setSessions(result.sessions);
            }
        });
    }, [selectedUser, forUser]);

    return (
        <>
            <Row>
                <Col>
                    <h1>Sessions</h1>
                </Col>
            </Row>
            {showSearch && currentUser.user_type === "ADMIN" &&
                <Row>
                    <Col>
                        <Card className="mb-3">
                            <Card.Body>
                                <Row>
                                    <Col sm={4}>
                                        <InputGroup>
                                            <InputGroup.Text>Search by User</InputGroup.Text>
                                            <AsyncTypeahead
                                                id="userSearch"
                                                onChange={(selected) => {
                                                    const item = selected[0];
                                                    if (item) {
                                                        console.log("Selected user " + item.id);
                                                        setSelectedUser({
                                                            user_id: item.id
                                                        });
                                                    }
                                                }}
                                                isLoading={searchLoading}
                                                onSearch={handleSearch}
                                                options={searchOptions}/>
                                        </InputGroup>
                                    </Col>
                                    <Col sm="auto">
                                        <ButtonGroup>
                                            <Button onClick={handleClearSearch}>Clear</Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <SessionListTable items={sessions}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}